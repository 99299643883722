import { Button, Icon } from '@material-ui/core';
import React from 'react';

class SnackbarUtilsClass {
	#snackBar = {
		enqueueSnackbar: () => { },
		closeSnackbar: () => { },
	};

	setSnackBar(enqueueSnackbar, closeSnackbar) {
		this.#snackBar.enqueueSnackbar = enqueueSnackbar;
		this.#snackBar.closeSnackbar = closeSnackbar;
	}

	success(msg, options = {}) {
		return this.toast(msg, { ...options, variant: "success", action: (keyyy) => <Button size='small' style={{ color: '#fff' }} onClick={() => this.closeSnackbar(keyyy)}><Icon fontSize='small' >close</Icon></Button> });
	}
	warning(msg, options = {}) {
		return this.toast(msg, { ...options, variant: "warning", action: (keyyy) => <Button size='small' style={{ color: '#fff' }} onClick={() => this.closeSnackbar(keyyy)}><Icon fontSize='small' >close</Icon></Button> });
	}
	info(msg, options = {}) {
		return this.toast(msg, { ...options, variant: "info", action: (keyyy) => <Button size='small' style={{ color: '#fff' }} onClick={() => this.closeSnackbar(keyyy)}><Icon fontSize='small' >close</Icon></Button> });
	}

	error(msg, options = {}) {
		return this.toast(msg, { ...options, variant: "error", action: (keyyy) => <Button size='small' style={{ color: '#fff' }} onClick={() => this.closeSnackbar(keyyy)}><Icon fontSize='small' >close</Icon></Button> });
	}
	toast(msg, options = {}) {
		const finalOptions = {
			variant: "default",
			...options,
		};
		const content = typeof msg === 'string' && options.isHtml
			? <span dangerouslySetInnerHTML={{ __html: msg }} />
			: msg;
		return this.#snackBar.enqueueSnackbar(content, { ...finalOptions });
	}
	closeSnackbar(key) {
		this.#snackBar.closeSnackbar(key);
	}
}

const SnackbarUtils = new SnackbarUtilsClass();

export default SnackbarUtils;
